import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Col } from 'react-flexbox-grid'
import 'animate.css/animate.min.css'
import ScrollAnimation from 'react-animate-on-scroll'

import './services.scss'

const ServiceItem = ({
  title, imgSrc, link,
}) => (
  <Col xs={6} sm={4} className='service-item'>
    <ScrollAnimation animateIn='flipInX' animateOnce>
      <Link to={link}>
        <img src={imgSrc} alt={title} />
        <div><b>{title}</b></div>
        <div><small><u>Learn more</u></small></div>
      </Link>
    </ScrollAnimation>
  </Col>
)

ServiceItem.propTypes = {
  title: PropTypes.string,
  imgSrc: PropTypes.string,
  link: PropTypes.string,
}

export default ServiceItem
