import React from 'react'
import PropTypes from 'prop-types'
import 'animate.css/animate.min.css'
import ScrollAnimation from 'react-animate-on-scroll'

import './featureItem.scss'

const FeatureItem = ({
  imgSrc, imgAlt, title, children,
}) => (
  <ScrollAnimation animateIn='flipInX' animateOnce className='feature-item'>
    <img className='feature-item-image' src={imgSrc} alt={imgAlt} />
    <h3 className='feature-item-title'>{title}</h3>
    <p>{children}</p>
  </ScrollAnimation>
)

FeatureItem.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
}

export default FeatureItem
