import React from 'react'
import { Grid, Row } from 'react-flexbox-grid'

import ServiceItem from './serviceItem'
import './services.scss'

const ServicesSection = () => (
  <div id='servicesSection' className='page-section services-container-section'>
    <h2>Services</h2>
    <Grid fluid>
      <Row className='services-row'>
        <ServiceItem
          link='covid19-cleaning'
          imgSrc='/images/services/covid19.svg'
          title='Covid-19 Cleaning'
        />
        <ServiceItem
          link='commercial-cleaning'
          imgSrc='/images/services/building-icon.svg'
          title='Commercial Cleaning'
        />
        <ServiceItem
          link='medical-center-cleaning'
          imgSrc='/images/services/cross-icon.svg'
          title='Medical centre Cleaning'
        />
        <ServiceItem
          link='window-cleaning'
          imgSrc='/images/services/window-icon.svg'
          title='Window cleaning'
        />
        <ServiceItem
          link='move-in-out-tenancy-cleaning'
          imgSrc='/images/services/box-icon.svg'
          title='Move in/out cleaning'
        />
        <ServiceItem
          link='carpet-cleaning'
          imgSrc='/images/services/vacuum-icon.svg'
          title='Carpet cleaning'
        />
      </Row>
    </Grid>
  </div>
)

export default ServicesSection
