import React from 'react'
import PropTypes from 'prop-types'
import 'animate.css/animate.min.css'

import Button from '../button'
import './hero.scss'
import HeroSlider from './slider'

const HeroSection = ({ video, text }) => (
  <div className='hero-container'>
    <HeroSlider />
    <img className='eco-icon' src='/images/eco-friendly.svg' alt='Eco friendly products' />
    {/* <video className='hero-video' playsInline autoPlay muted loop id='heroVideo'>
      <source src={video} type='video/mp4' />
    </video>
    <div className='hero-content page-content page-section'>
      <div>
        <h1>Commercial Cleaning</h1>
        <h3 className='hero-text'>{text}</h3>
      </div>
      <div className='hero-buttons'>
        <Button id='home-hero-get-free-quote' secondary to='/free-quote'>Get a Free Quote</Button>
        <Button id='home-hero-covid19' to='/covid19-cleaning'>Covid-19 cleaning</Button>
      </div>
    </div>
    <img className='eco-icon' src='/images/eco-friendly.svg' alt='Eco friendly products' /> */}
    <div>
      <a href='#servicesSection'>
        <img className='down-arrow animated infinite bounce delay-2s slow' src='/images/down.svg' alt='Scroll down' />
      </a>
    </div>
  </div>
)

HeroSection.propTypes = {
  video: PropTypes.string,
  text: PropTypes.string,
}

export default HeroSection
